export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REMOVE_CURRENT_USER = "REMOVE_CURRENT_USER";
export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SET_USER_PROJETS = "SET_USER_PROJETS";
export const SET_CURRENT_USER_MESSAGE = "SET_CURRENT_USER_MESSAGE";

export const SET_PAIEMENT_PENDING = "SET_PAIEMENT_PENDING";
export const SET_PAIEMENT_FAILED = "SET_PAIEMENT_FAILED";
export const SET_PAIEMENT_DONE = "SET_PAIEMENT_DONE";
export const SET_PAIEMENT_REFERENCE = "SET_PAIEMENT_REFERENCE";

export const SET_SECTEUR = "SET_SECTEUR";
export const IS_LOADING = "IS_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const STOP_APP_LOADING = "STOP_APP_LOADING";
export const SET_APP_LOADING = "SET_APP_LOADING";