const pays = [
    "Afghanistan",
    "Afrique du Sud",
    "Albanie",
    "Algérie",
    "Allemagne",
    "Andorre",
    "Angola",
    "Antigua-et-Barbuda",
    "Arabie saoudite",
    "Argentine",
    "Arménie",
    "Australie",
    "Autriche",
    "Azerbaïdjan",
    "Bahamas",
    "Bahreïn",
    "Bangladesh",
    "Barbade",
    "Belgique",
    "Belize",
    "Bhoutan",
    "Birmanie",
    "Biélorussie",
    "Bolivie",
    "Bosnie-Herzégovine",
    "Botswana",
    "Brunei",
    "Brésil",
    "Bulgarie",
    "Burkina",
    "Burundi",
    "Bénin",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Cap-Vert",
    "Centrafrique",
    "Chili",
    "Chine",
    "Chypre",
    "Colombie",
    "Comores",
    "Congo",
    "Corée du Nord",
    "Corée du Sud",
    "Costa Rica",
    "Croatie",
    "Cuba",
    "Côte d'Ivoire",
    "Danemark",
    "Djibouti",
    "Dominique",
    "Egypte",
    "Emirats arabes unis",
    "Equateur",
    "Erythrée",
    "Espagne",
    "Estonie",
    "Etats-Unis",
    "Ethiopie",
    "Fidji",
    "Finlande",
    "France",
    "Gabon",
    "Gambie",
    "Ghana",
    "Grenade",
    "Grèce",
    "Guatemala",
    "Guinée",
    "Guinée équatoriale",
    "Guinée-Bissao",
    "Guyana",
    "Géorgie",
    "Haïti",
    "Honduras",
    "Hongrie",
    "Inde",
    "Indonésie",
    "Irak",
    "Iran",
    "Irlande",
    "Islande",
    "Israël",
    "Italie",
    "Jamaïque",
    "Japon",
    "Jordanie",
    "Jérusalem - Territoires palestiniens",
    "Kazakhstan",
    "Kenya",
    "Kirghizstan",
    "Kiribati",
    "Kosovo",
    "Koweït",
    "Laos",
    "Lesotho",
    "Lettonie",
    "Liban",
    "Liberia",
    "Libye",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg",
    "Macédoine",
    "Madagascar",
    "Malaisie",
    "Malawi",
    "Maldives",
    "Mali",
    "Malte",
    "Maroc",
    "Marshall",
    "Maurice",
    "Mauritanie",
    "Mexique",
    "Micronésie",
    "Moldavie",
    "Monaco",
    "Mongolie",
    "Monténégro",
    "Mozambique",
    "Namibie",
    "Nauru",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norvège",
    "Nouvelle-Zélande",
    "Népal",
    "Oman",
    "Ouganda",
    "Ouzbékistan",
    "Pakistan",
    "Palaos",
    "Panama",
    "Papouasie-Nouvelle-Guinée",
    "Paraguay",
    "Pays-Bas",
    "Philippines",
    "Pologne",
    "Portugal",
    "Pérou",
    "Qatar",
    "Roumanie",
    "Royaume-Uni",
    "Russie",
    "Rwanda",
    "République dominicaine",
    "République démocratique du Congo",
    "République tchèque",
    "Saint-Christophe-et-Niévès",
    "Saint-Marin",
    "Saint-Siège",
    "Saint-Vincent-et-les Grenadines",
    "Sainte-Lucie",
    "Salomon",
    "Salvador",
    "Samoa",
    "Sao Tomé-et-Principe",
    "Serbie",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovénie",
    "Somalie",
    "Soudan",
    "Sri Lanka",
    "Suisse",
    "Suriname",
    "Suède",
    "Swaziland",
    "Syrie",
    "Sénégal",
    "Tadjikistan",
    "Tanzanie",
    "Taïwan",
    "Tchad",
    "Thaïlande",
    "Timor oriental",
    "Togo",
    "Tonga",
    "Trinité-et-Tobago",
    "Tunisie",
    "Turkménistan",
    "Turquie",
    "Tuvalu",
    "Ukraine",
    "Uruguay",
    "Vanuatu",
    "Venezuela",
    "Viêt Nam",
    "Yémen",
    "Zambie",
    "Zimbabwe",
];

export default pays;